<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warehouseId" clearable>
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index"
                               :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                        :editable="false"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        v-model="queryParams.createTime"
                        placeholder="请选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-select v-model="queryParams.createBy" placeholder="请选择司机" clearable filterable>
                    <el-option v-for="(item, index) in drivers" :label="item.driverName" :value="item.guid" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch">查询</el-button>
            </el-form-item>
        </el-form>

       <el-row>
            <el-col :span="4" v-for="(o, index) in tableData" :key="o" :offset="index >= 0 ? 0: 0" >
                <el-card  style="height: 300px; margin-bottom: 20px;margin-right: 20px;box-sizing: border-box">
                    <el-image :src="o" :preview-src-list="tableData"></el-image>
                </el-card>
            </el-col>
        </el-row>


        <page-item :total="total" ref="pageItem"/>
        <image-view ref="imageView"/>
    </div>
</template>


<script>
    import PageItem from '@/components/PageItem/'
    import ImageView from '@/components/ImageView/'
    import {getAllDriver} from '@/api/system/driver'
    import {getPageImg} from "@/api/delivery/driver_clock";

    export default {
        name: "DriverClockImg",
        components: {PageItem, ImageView},
        data(){
            return{
                queryParams: {
                    warehouseId: undefined,
                    createTime: undefined,
                    createBy: undefined
                },
                warehouseOption: this.$store.getters.warehouse,
                drivers: [],
                tableData: [],
                total: 0,
                loading: false,
            }
        },
        methods: {
            getTableData(){
                if(!this.queryParams.warehouseId){
                    this.$message.error("请选择仓库");
                    return;
                }
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getPageImg(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            querySearch(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
        },
        watch: {
            'queryParams.warehouseId': function (val, oldVal) {
                if(val) {
                    getAllDriver({warId: val}).then(({code, data}) => {
                        if (code === 0) {
                            this.drivers = data;
                        }
                    })
                }
            }
        }
    }

</script>



<style lang="scss" scoped>

    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .button {
        padding: 0;
        float: right;
    }

    .image {
        width: 100%;
        display: block;
    }
    /deep/ .el-card__body{
        height: 100%;
    }
    /deep/ .el-image{
        width: 100%;
        height: 90%;
        display: flex;
        align-items: center;
    }
    /deep/ .el-image img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

</style>
